<template>
  <v-bottom-navigation
    v-if="isMobile"
    fixed
    grow
    app
    height="66"
    :hide-on-scroll="false"
    class="pb-3"
  >
    <v-btn class="bottom-button" color="#1B263B" @click="handleOpenLink">
      <span>
        Login
      </span>
      <v-icon color="#E0E1DD">mdi-login</v-icon>
    </v-btn>
    <v-btn class="bottom-button" color="#1B263B" @click="handleOpenLink">
      <span>
        Register
      </span>
      <v-icon color="#E0E1DD">mdi-account-plus</v-icon>
    </v-btn>
    <v-btn class="bottom-button" color="#1B263B" @click="handleOpenLink">
      <span>
        Promo
      </span>
      <v-icon color="#E0E1DD">mdi-ticket-percent</v-icon>
    </v-btn>

  </v-bottom-navigation>
</template>

<script>
export default {
  name: 'BottomNavbar',

  data () {
    return {
    }
  },

  computed: {
    isMobile () {
      return window.innerWidth < 600
    },
  },
  methods: {
    handleOpenLink () {
      window.location.href = 'https://rebrand.ly/gapai567'
    },
  }
}
</script>

<style scoped>
  .basic {
    color: #E0E1DD;
    text-decoration: none;
    /* background-color: #1B263B; */
  }
  .v-item-group.v-bottom-navigation--fixed {
    position: fixed;
    background-color: #1B263B;
  }

  .bottom-button {
    height: 100% !important;
  }
</style>