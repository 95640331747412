<template>
  <v-app>
    <NavigationBar />
    <v-main>
      <div :class="isMobile ? 'header-wrapper-mobile' : 'header-wrapper'" id="home">
        <div class="gradient" :class="isMobile ? 'gradient-mobile' : ''"></div>
        <div class="header-text-wrapper">
          <h1>GAPAI567 RTP Game Online Tertinggi Live 24 Jam Tanpa Henti</h1>
          <p>Gapai567 setiap Menit Update RTP Game Online Tertinggi yang live selama 24 jam setiap hari tanpa henti.</p>
        </div>
      </div>
      <router-view/>
    </v-main>
    <BottomNav />
  </v-app>
</template>

<script>
import NavigationBar from './components/Navbar';
import BottomNav from './components/BottomNav';

export default {
  name: 'App',
  components: {
    NavigationBar,
    BottomNav,
  },
  computed: {
    isMobile() {
      return window.innerWidth < 600;
    },
  },
  data: () => ({
    //
  }),
};
</script>

<style scoped>
.theme--light.v-application {
  background: #0D1B2A;
  overflow: hidden;
}

.header-wrapper {
  background: #1B263B;
  color: #E0E1DD;
  margin-top: -4rem;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.header-text-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-text-wrapper h1 {
  font-family: 'Raleway', sans-serif;
  font-size: 48px;
  /* padding: 20rem 4rem 1rem; */
}

.header-text-wrapper p {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 0rem 15rem;
  text-align: center;
}

.header-wrapper-mobile {
  background: #1B263B;
  color: #E0E1DD;
  margin-top: -4rem;
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-wrapper-mobile h1 {
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  padding: 5rem 1rem 1rem;
  text-align: center;
  line-height: 32px;
}

.header-wrapper-mobile p {
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 0rem 3rem;
  text-align: center;
}




@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.gradient {
  filter: blur(50px);
  background-image: linear-gradient(rgba(55, 235, 169, 0.85), #5b37eb);
  animation: rotate 25s cubic-bezier(0.8, 0.2, 0.2, 0.8) alternate infinite;
  border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
  width: 250px;
  height: 250px;
  position: absolute;
  margin-top: 6rem;
}


.gradient-mobile {
  filter: blur(50px);
  background-image: linear-gradient(rgba(55, 235, 169, 0.85), #5b37eb);
  animation: rotate 25s cubic-bezier(0.8, 0.2, 0.2, 0.8) alternate infinite;
  border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
  width: 175px;
  height: 175px;
  position: absolute;
  margin-top: 7rem
}

/* @media (min-width: 720px) {
  .gradient {
    background: #1B263B;
    color: #E0E1DD;
    margin-top: -4rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
} */
body {
  background-color: #222;
  position: absolute;
  inset: 0;
  display: flex;
  place-content: center;
  align-items: center;
  overflow: hidden;
}

/* This is just to transition when you change the viewport size. */
* {
  transition: all 0.25s ease-out;
}


</style>